import React from 'react';
import './Suvs.css';
import ContactContainer from '../../Contact/ContactContainer';

const Suvs = () => {
  return (
    <div>
      <div className="suv-service">
      <div className="visually-hidden">
          <p>Professional SUV detailing services in Gainesville, Florida.</p>
          <p>Expert SUV detailing in Ocala, Florida.</p>
          <p>Top-notch SUV detailing in The Villages, Florida.</p>
          <p>Best SUV detailing services in Orlando, Florida.</p>
          <p>Best SUV detailing services in Brevard County, Florida.</p>
          <p>Best SUV detailing services in Alachua County, Florida.</p>
          <p>Best SUV detailing services in Sumter County, Florida.</p>
          <p>Best SUV detailing services in Marion County, Florida.</p>
          <p>Best SUV detailing services in Orange County, Florida.</p>
          <p>Quality SUV detailing in Viera, Florida.</p>
          <p>Affordable SUV detailing in Melbourne, Florida.</p>
        </div>
      </div>
      <ContactContainer heading="Book your SUV detailing appointment today!"/>
    </div>
  );
};

export default Suvs;
