import React from 'react';
import './About.css';

const About = () => {
  return (
    <div>
      <div className="hero-about">
      </div>
    </div>
  );
};

export default About;
