import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Services from './components/Services/Services';
import About from './components/About/About';
import Main from './components/Main/Main';
import Cars from './components/Services/Cars/Cars';
import GolfCart from './components/Services/GolfCart/GolfCart';
import Trucks from './components/Services/Trucks/Trucks';
import Suv from './components/Services/Suvs/Suvs';
import Contact from './components/Contact/Contact';
import './index.css'; // Ensure you import the CSS file here

const App = () => {
  return (
    <Router>
      <div id="root">
        <Header />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services/cars" element={<Cars />} />
            <Route path="/services/golf-cart" element={<GolfCart />} />
            <Route path="/services/trucks" element={<Trucks />} />
            <Route path="/services/suv" element={<Suv />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
