import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [stateMessage, setStateMessage] = useState(null);

    const sendEmail = (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        emailjs
            .sendForm(
                process.env.REACT_APP_SERVICE_ID,
                process.env.REACT_APP_TEMPLATE_ID,
                e.target,
                process.env.REACT_APP_USER_ID
            )
            .then(
                (result) => {
                    console.log('EmailJS result:', result);
                    setStateMessage('Message sent!');
                    setIsSubmitting(false);
                    setTimeout(() => {
                        setStateMessage(null);
                    }, 5000); // hide message after 5 seconds
                },
                (error) => {
                    console.error('EmailJS error:', error);
                    setStateMessage('Something went wrong, please try again later');
                    setIsSubmitting(false);
                    setTimeout(() => {
                        setStateMessage(null);
                    }, 5000); // hide message after 5 seconds
                }
            );

        // Clears the form after sending the email
        e.target.reset();
    };

    return (
        <form onSubmit={sendEmail} className="contact-form">
            <label>Name</label>
            <input type="text" name="user_name" required />
            <label>Email</label>
            <input type="email" name="user_email" required />
            <label>Phone Number</label>
            <input type="tel" name="user_phone" required />
            <label>Type of Vehicle</label>
            <select name="vehicle_type" required>
                <option value="">Select a vehicle</option>
                <option value="car">Car</option>
                <option value="truck">Truck</option>
                <option value="golf_cart">Golf Cart</option>
                <option value="suv">SUV</option>
            </select>
            <label>Message</label>
            <textarea name="message" />
            <input type="submit" value="Send" disabled={isSubmitting} />
            {stateMessage && <p>{stateMessage}</p>}
        </form>
    );
};

export default ContactForm;
