import React from 'react';
import './Cars.css';
import ContactContainer from '../../Contact/ContactContainer';

const Cars = () => {
  return (
    <div>
      <div className="cars-service">
        <div className="visually-hidden">
          <p>Professional car detailing services in Gainesville, Florida.</p>
          <p>Expert car detailing in Ocala, Florida.</p>
          <p>Top-notch car detailing in The Villages, Florida.</p>
          <p>Best car detailing services in Orlando, Florida.</p>
          <p>Best car detailing services in Brevard County, Florida.</p>
          <p>Best car detailing services in Alachua County, Florida.</p>
          <p>Best car detailing services in Sumter County, Florida.</p>
          <p>Best car detailing services in Marion County, Florida.</p>
          <p>Best car detailing services in Orange County, Florida.</p>
          <p>Quality car detailing in Viera, Florida.</p>
          <p>Affordable car detailing in Melbourne, Florida.</p>
        </div>
      </div>
      <ContactContainer heading="Book your car detailing appointment today!"/>
    </div>
  );
};

export default Cars;
