import React from 'react';
import './Trucks.css';
import ContactContainer from '../../Contact/ContactContainer';

const Trucks = () => {
  return (
    <div>
      <div className="trucks-service">
      <div className="visually-hidden">
          <p>Professional truck detailing services in Gainesville, Florida.</p>
          <p>Expert truck detailing in Ocala, Florida.</p>
          <p>Top-notch truck detailing in The Villages, Florida.</p>
          <p>Best truck detailing services in Orlando, Florida.</p>
          <p>Best truck detailing services in Brevard County, Florida.</p>
          <p>Best truck detailing services in Alachua County, Florida.</p>
          <p>Best truck detailing services in Sumter County, Florida.</p>
          <p>Best truck detailing services in Marion County, Florida.</p>
          <p>Best truck detailing services in Orange County, Florida.</p>
          <p>Quality truck detailing in Viera, Florida.</p>
          <p>Affordable truck detailing in Melbourne, Florida.</p>
        </div>
      </div>
      <ContactContainer heading="Book your truck detailing appointment today!"/>
    </div>
  );
};

export default Trucks;
