import React from 'react';
import ContactForm from './ContactForm';
import './ContactContainer.css';

const ContactContainer  = ({ heading }) => {
  return (
    <div className="contact-container">
      <h1>{heading}</h1>
      <ContactForm />
    </div>
  );
};

export default ContactContainer;
