import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Main.css'; // Importing the CSS file for styling

import carImg from '../../assets/servicesAssets/car.jpg';
import truckImg from '../../assets/servicesAssets/truck.jpg';
import golfCartImg from '../../assets/servicesAssets/golfcart.jpg';
import suvImg from '../../assets/servicesAssets/suv.jpg';

// Define the dimensions of your images
const carImgDimensions = { width: 600, height: 400 };
const truckImgDimensions = { width: 600, height: 400 };
const golfCartImgDimensions = { width: 600, height: 400 };
const suvImgDimensions = { width: 600, height: 400 };

const services = [
  { title: 'Cars', image: carImg, link: '/services/cars', alt: 'Mobile Car Detailing', dimensions: carImgDimensions },
  { title: 'Trucks', image: truckImg, link: '/services/trucks', alt: 'Mobile Truck Detailing', dimensions: truckImgDimensions },
  { title: 'Golf Carts', image: golfCartImg, link: '/services/golf-cart', alt: 'Mobile Golf Cart Detailing', dimensions: golfCartImgDimensions },
  { title: 'SUVs', image: suvImg, link: '/services/suv', alt: 'Mobile SUV Detailing', dimensions: suvImgDimensions }
];

const Main = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Check initial size
    window.addEventListener('resize', handleResize); // Listen for resize events

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up on unmount
    };
  }, []);

  return (
    <div className="image-container">
      <div className="section hero">
        <h1 className="visually-hidden">Welcome to Atlas Detail - Mobile Auto Detailing in Florida</h1>
        <p className="visually-hidden">We provide professional mobile detailing services for cars, trucks, SUVs, and golf carts in Gainesville, Ocala, The Villages, Orlando, Vierra, and Melbourne.</p>
      </div>
      {!isMobile && (
        <div>
          <div className="section services1">
            <h2 className="visually-hidden">Our Services</h2>
            <p className="visually-hidden">We specialize in mobile detailing for various vehicles. Check out our services below:</p>
          </div>
          <div className="section services2">
            <ul className="visually-hidden">
              <li><Link to="/services/cars">Mobile Car Detailing</Link></li>
              <li><Link to="/services/trucks">Mobile Truck Detailing</Link></li>
              <li><Link to="/services/golf-cart">Mobile Golf Cart Detailing</Link></li>
              <li><Link to="/services/suv">Mobile SUV Detailing</Link></li>
            </ul>
          </div>
        </div>
      )}
      {isMobile && (
        <div className="services-grid-main">
          {services.map(service => (
            <Link to={service.link} key={service.title} className="service-card-main">
              <img 
                src={service.image} 
                alt={service.alt} 
                width={service.dimensions.width} 
                height={service.dimensions.height} 
              />
              <div className="service-title-main">{service.title}</div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Main;
