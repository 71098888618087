import React from 'react';
import { Link } from 'react-router-dom';
import './Services.css';

import carImg from '../../assets/servicesAssets/car.jpg';
import truckImg from '../../assets/servicesAssets/truck.jpg';
import golfCartImg from '../../assets/servicesAssets/golfcart.jpg';
import suvImg from '../../assets/servicesAssets/suv.jpg';

const services = [
  { title: 'Cars', image: carImg, link: '/services/cars', description: 'Professional car detailing services.' },
  { title: 'Trucks', image: truckImg, link: '/services/trucks', description: 'Expert truck cleaning and detailing.' },
  { title: 'Golf Carts', image: golfCartImg, link: '/services/golf-cart', description: 'Detailing services for golf carts.' },
  { title: 'SUVs', image: suvImg, link: '/services/suv', description: 'Specialized SUV cleaning and detailing.' }
];

const Services = () => {
  return (
    <div>
      {/* Hidden SEO content */}
      <div className="hidden-seo">
        {services.map(service => (
          <div key={service.title}>
            <span>{service.title}</span>
            <span>{service.description}</span>
          </div>
        ))}
      </div>

      {/* Visible content for users */}
      <div className="services-grid">
        {services.map(service => (
          <Link to={service.link} key={service.title} className="service-card">
            <img src={service.image} alt={service.title} />
            <div className="service-title">{service.title}</div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Services;
