import React from 'react';
import ContactContainer from './ContactContainer';

const Contact = () => {
  return (
    <div>
      <ContactContainer heading="Need something? Contact Us!"/>
    </div>
  );
};

export default Contact;
