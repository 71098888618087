import React from 'react';
import './GolfCart.css';
import ContactContainer from '../../Contact/ContactContainer';

const GolfCart = () => {
  return (
    <div>
      <div className="golf-cart-service">
      <div className="visually-hidden">
          <p>Professional golf cart detailing services in Gainesville, Florida.</p>
          <p>Expert golf cart detailing in Ocala, Florida.</p>
          <p>Top-notch golf cart detailing in The Villages, Florida.</p>
          <p>Best golf cart detailing services in Orlando, Florida.</p>
          <p>Best golf cart detailing services in Brevard County, Florida.</p>
          <p>Best golf cart detailing services in Alachua County, Florida.</p>
          <p>Best golf cart detailing services in Sumter County, Florida.</p>
          <p>Best golf cart detailing services in Marion County, Florida.</p>
          <p>Best golf cart detailing services in Orange County, Florida.</p>
          <p>Quality golf cart detailing in Viera, Florida.</p>
          <p>Affordable golf cart detailing in Melbourne, Florida.</p>
        </div>
      </div>
      <ContactContainer heading="Book your golf cart detailing appointment today!"/>
    </div>
  );
};

export default GolfCart;
